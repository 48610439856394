import IslandYellow from "assets/landing_page_island_yellow.svg";
import IslandGreen from "assets/landing_page_island_green.svg";
import Button from "button";
import styles from "./styles.module.scss";
import useBadge from "use_badge";

const Hero = () => {
  const { issued } = useBadge("ccw-avatar-maker");
  const routes = useRoutes();

  return (
    <>
      <div className={styles.hero_background}>
        <main className={styles.main}>
          <img src={IslandYellow} alt="" className={styles.island_yellow} />
          <section className={styles.copy_and_cta}>
            <h1>Learn. Make. Together. Anywhere.</h1>
            <p>
              Code Club World helps you make cool stuff while learning to code,
              with free coding games and activities for kids to explore.
            </p>

            {issued ? (
              <Button.RectanglePrimary
                href={routes.mapPath()}
                text="Continue your journey!"
                outline
                className={styles.button_no_border}
              />
            ) : (
              <Button.RectanglePrimary
                href={routes.newLaunchPath()}
                text="Get started"
                outline
                className={styles.button_no_border}
              />
            )}
          </section>
          <img src={IslandGreen} alt="" className={styles.island_green} />
        </main>
      </div>
    </>
  );
};

export default Hero;
