const CodeClubWorldLogo = ({ scale = 1, white = false }) => {
  let color = white ? "#ffffff" : "#000000";
  return (
    <svg
      width={187 * scale}
      height={62 * scale}
      viewBox="0 0 187 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      title="Code Club World Logo"
    >
      <g clipPath="url(#clip0_1422_237)">
        <path
          d="M59.9763 30.9986C59.9763 47.5575 46.5527 60.9811 29.9938 60.9811C13.4348 60.9811 0.0111694 47.5575 0.0111694 30.9986C0.0111694 14.4397 13.4348 1.01599 29.9938 1.01599C46.5527 1.01599 59.9763 14.4397 59.9763 30.9986Z"
          fill="#41B653"
        />
        <path
          d="M20.7361 30.0889C21.2083 29.8807 21.5783 29.607 21.8459 29.2685L20.9368 28.1586C20.8029 28.3239 20.6553 28.4657 20.4942 28.5837C20.3326 28.7019 20.1614 28.7903 19.9805 28.8493C19.7993 28.9084 19.6065 28.9379 19.4018 28.9379C19.087 28.9379 18.8036 28.8574 18.5519 28.6958C18.2998 28.5346 18.1013 28.3221 17.9557 28.0584C17.8099 27.7947 17.7372 27.4935 17.7372 27.1552C17.7372 26.8088 17.8081 26.5019 17.9497 26.2342C18.0914 25.9667 18.2862 25.7559 18.5342 25.6025C18.782 25.4491 19.0673 25.3722 19.3901 25.3722C19.5946 25.3722 19.7934 25.4077 19.9864 25.4786C20.1792 25.5495 20.3583 25.646 20.5236 25.7678C20.6888 25.8899 20.8265 26.0258 20.9368 26.1751L21.8578 25.0653C21.5981 24.7034 21.2358 24.42 20.7716 24.2153C20.3071 24.0107 19.7954 23.9082 19.2367 23.9082C18.6385 23.9082 18.1032 24.0499 17.631 24.3334C17.1588 24.6166 16.7869 25.0045 16.5152 25.4963C16.2436 25.9883 16.1079 26.5411 16.1079 27.1552C16.1079 27.777 16.2436 28.3318 16.5152 28.8199C16.7869 29.308 17.1588 29.6936 17.631 29.977C18.1032 30.2603 18.6385 30.4019 19.2367 30.4019C19.764 30.4019 20.2639 30.2975 20.7361 30.0889Z"
          fill="white"
        />
        <path
          d="M27.2101 28.0701C27.0725 28.3416 26.8815 28.5541 26.6376 28.7076C26.3935 28.8611 26.118 28.9379 25.8111 28.9379C25.4962 28.9379 25.2168 28.8611 24.9729 28.7076C24.7288 28.5541 24.536 28.3416 24.3944 28.0701C24.2527 27.7986 24.1857 27.4935 24.1936 27.1552C24.1857 26.8088 24.2527 26.5019 24.3944 26.2342C24.536 25.9667 24.7288 25.7542 24.9729 25.5966C25.2168 25.4393 25.4962 25.3606 25.8111 25.3606C26.118 25.3606 26.3935 25.4372 26.6376 25.5907C26.8815 25.7442 27.0725 25.9567 27.2101 26.2283C27.3478 26.5 27.4169 26.8088 27.4169 27.1552C27.4169 27.4935 27.3478 27.7986 27.2101 28.0701ZM28.6802 28.8317C28.9635 28.3437 29.1051 27.7849 29.1051 27.1552C29.1051 26.5255 28.9635 25.9685 28.6802 25.4845C28.3969 25.0005 28.0052 24.6166 27.5054 24.3334C27.0054 24.0499 26.4408 23.9082 25.8111 23.9082C25.1736 23.9082 24.6049 24.0499 24.105 24.3334C23.6052 24.6166 23.2117 25.0005 22.9244 25.4845C22.6371 25.9685 22.4934 26.5255 22.4934 27.1552C22.4934 27.7849 22.6371 28.3437 22.9244 28.8317C23.2117 29.3198 23.6052 29.7034 24.105 29.9827C24.6049 30.2623 25.1736 30.4019 25.8111 30.4019C26.4408 30.4019 27.0054 30.2623 27.5054 29.9827C28.0052 29.7034 28.3969 29.3198 28.6802 28.8317Z"
          fill="white"
        />
        <path
          d="M20.5434 35.204C20.7915 35.0505 21.0767 34.9739 21.3995 34.9739C21.6041 34.9739 21.8028 35.0092 21.9958 35.0801C22.1886 35.151 22.3676 35.2474 22.533 35.3694C22.6981 35.4916 22.8358 35.6273 22.9461 35.7766L23.867 34.667C23.6074 34.3049 23.2452 34.0216 22.7809 33.8168C22.3164 33.6122 21.8047 33.5099 21.2459 33.5099C20.6477 33.5099 20.1126 33.6515 19.6402 33.9348C19.1679 34.2182 18.7962 34.6061 18.5247 35.0978C18.2531 35.5898 18.1172 36.1428 18.1172 36.7566C18.1172 37.3786 18.2531 37.9335 18.5247 38.4214C18.7962 38.9097 19.1679 39.2952 19.6402 39.5785C20.1126 39.8618 20.6477 40.0034 21.2459 40.0034C21.7732 40.0034 22.2732 39.8992 22.7454 39.6906C23.2177 39.4822 23.5876 39.2086 23.8552 38.8701L22.9461 37.7603C22.8122 37.9256 22.6646 38.0672 22.5034 38.1852C22.342 38.3034 22.1707 38.3919 21.9899 38.4509C21.8087 38.51 21.6159 38.5394 21.4114 38.5394C21.0965 38.5394 20.8131 38.4589 20.5612 38.2975C20.3093 38.1363 20.1106 37.9236 19.9649 37.6598C19.8193 37.3963 19.7466 37.0952 19.7466 36.7566C19.7466 36.4104 19.8174 36.1035 19.959 35.8357C20.1008 35.5681 20.2956 35.3576 20.5434 35.204Z"
          fill="white"
        />
        <path
          d="M26.6048 31.5668H24.9282V39.8855H26.6048V31.5668Z"
          fill="white"
        />
        <path
          d="M31.9297 37.3829C31.9297 37.556 31.8986 37.7176 31.8362 37.867C31.7738 38.0166 31.688 38.1466 31.579 38.2566C31.4697 38.3669 31.3372 38.4514 31.1814 38.5105C31.0253 38.5694 30.8579 38.599 30.6785 38.599C30.429 38.599 30.2185 38.544 30.0472 38.4337C29.8756 38.3235 29.7431 38.1641 29.6496 37.9556C29.556 37.747 29.5093 37.501 29.5093 37.2175V33.6402H27.8487V37.4892C27.8487 38.0166 27.9365 38.4673 28.112 38.8411C28.2873 39.215 28.5366 39.5022 28.8603 39.7031C29.1837 39.9036 29.5716 40.0039 30.0237 40.0039C30.3746 40.0039 30.7019 39.937 31.006 39.8033C31.31 39.6697 31.5711 39.4846 31.7895 39.2484C31.8571 39.1751 31.9166 39.0983 31.9724 39.0199L31.9725 39.0259H31.9704V39.8859H33.5902V33.6402H31.9297V37.3829Z"
          fill="white"
        />
        <path
          d="M39.4101 37.7072C39.2767 37.9794 39.0936 38.1918 38.8608 38.3443C38.6295 38.4982 38.3561 38.5746 38.0407 38.5746C37.7254 38.5746 37.4506 38.4982 37.2141 38.3443C36.9776 38.1918 36.7933 37.9794 36.6599 37.7072C36.5251 37.4364 36.4589 37.1186 36.4589 36.7573C36.4589 36.4027 36.5251 36.0886 36.6599 35.8127C36.7933 35.5366 36.9776 35.3243 37.2141 35.1744C37.4506 35.0257 37.7254 34.9506 38.0407 34.9506C38.3561 34.9506 38.6307 35.0257 38.8672 35.1744C39.1037 35.3243 39.2868 35.5366 39.4166 35.8127C39.5463 36.0886 39.6111 36.4027 39.6111 36.7573C39.6111 37.1186 39.5437 37.4364 39.4101 37.7072ZM39.8591 33.9295C39.4343 33.6496 38.9498 33.5097 38.4069 33.5097C38.1476 33.5097 37.8945 33.5493 37.6516 33.628C37.4074 33.7069 37.1824 33.815 36.9776 33.9523C36.7971 34.0744 36.6432 34.213 36.5174 34.3656V34.3668L36.4589 34.4407V31.5668H34.8415V39.8627H36.4232L36.4627 39.1646C36.5937 39.3121 36.7489 39.4443 36.9307 39.5613C37.143 39.6986 37.3795 39.8068 37.6389 39.8855C37.8996 39.9644 38.1666 40.0037 38.4426 40.0037C38.978 40.0037 39.4559 39.8641 39.8769 39.5842C40.2978 39.3057 40.6296 38.9217 40.8738 38.4333C41.1179 37.9451 41.24 37.3869 41.24 36.7573C41.24 36.1267 41.1167 35.5683 40.8687 35.0802C40.6207 34.592 40.2837 34.2091 39.8591 33.9295Z"
          fill="white"
        />
        <path
          d="M34.6402 28.1236C34.5061 28.3992 34.3213 28.6155 34.0851 28.7729C33.8491 28.9304 33.5734 29.0091 33.2588 29.0091C32.9439 29.0091 32.6702 28.9304 32.4383 28.7729C32.206 28.6155 32.025 28.3992 31.895 28.1236C31.7652 27.8481 31.7002 27.5255 31.7002 27.1556C31.7002 26.7935 31.7652 26.4727 31.895 26.1933C32.025 25.9138 32.206 25.6973 32.4383 25.5439C32.6702 25.3904 32.9439 25.3136 33.2588 25.3136C33.5734 25.3136 33.8491 25.3904 34.0851 25.5439C34.3213 25.6973 34.5061 25.9138 34.6402 26.1933C34.7739 26.4727 34.8408 26.7935 34.8408 27.1556C34.8408 27.5255 34.7739 27.8481 34.6402 28.1236ZM34.8395 30.2842H36.4584V21.5472H34.7818V24.6996C34.6861 24.5998 34.579 24.505 34.4572 24.4163C34.2406 24.2588 33.9967 24.1351 33.7252 24.0444C33.4536 23.954 33.1721 23.9086 32.8809 23.9086C32.3299 23.9086 31.84 24.0503 31.411 24.3338C30.9818 24.617 30.6436 25.0028 30.3957 25.4907C30.1477 25.9788 30.0238 26.5337 30.0238 27.1556C30.0238 27.793 30.1477 28.3539 30.3957 28.8379C30.6436 29.3219 30.9839 29.7038 31.4169 29.9831C31.8497 30.2626 32.3378 30.4023 32.8809 30.4023C33.1721 30.4023 33.4495 30.361 33.7132 30.2783C33.9769 30.1957 34.2131 30.0816 34.4216 29.9359C34.5873 29.8203 34.726 29.6929 34.8395 29.5548V30.2842Z"
          fill="white"
        />
        <path
          d="M39.3147 26.4742C39.4001 26.1101 39.5698 25.8328 39.8201 25.6485C40.0959 25.4455 40.4185 25.3428 40.7792 25.3428C41.1534 25.3428 41.4701 25.4543 41.7203 25.6742C41.9521 25.878 42.0839 26.1403 42.1217 26.4742H39.3147ZM43.486 25.4043C43.2242 24.9432 42.8555 24.5745 42.3901 24.3085C41.9249 24.0429 41.383 23.9081 40.7792 23.9081C40.1347 23.9081 39.5659 24.0491 39.0885 24.3271C38.6107 24.6055 38.2317 24.9991 37.9621 25.4971C37.6931 25.9938 37.5565 26.568 37.5565 27.2036C37.5565 27.8319 37.6954 28.3944 37.9691 28.8752C38.2432 29.3571 38.6266 29.7363 39.1083 30.0021C39.589 30.2673 40.1472 30.4018 40.7671 30.4018C41.2634 30.4018 41.7152 30.3091 42.1101 30.1263C42.5046 29.9438 42.8418 29.6887 43.1123 29.3683C43.3818 29.0488 43.5777 28.6887 43.6942 28.2983L43.7267 28.189H41.9777L41.9549 28.2381C41.8565 28.4497 41.7051 28.6241 41.5047 28.7563C41.3051 28.8882 41.0527 28.9552 40.7549 28.9552C40.3568 28.9552 40.0139 28.8275 39.7356 28.5757C39.4769 28.3419 39.3235 28.0196 39.2788 27.6169H43.8497L43.8556 27.5383C43.8636 27.4322 43.8698 27.3296 43.8739 27.2314C43.8781 27.133 43.8801 27.0386 43.8801 26.9482C43.8801 26.3844 43.7474 25.8652 43.486 25.4043Z"
          fill="white"
        />
        <path
          d="M11.6276 30.8518L11.4125 31.0002L11.6276 31.146C11.9025 31.3373 12.1537 31.5525 12.3761 31.7843C12.4956 31.9113 12.6079 32.0403 12.7179 32.1839C13.7342 33.5181 13.8873 35.1465 13.8873 35.9953V42.3893C13.8873 43.4247 14.772 44.2664 15.8601 44.2664H17.4359V45.9426H15.8601C13.7821 45.9426 12.0938 44.3477 12.0938 42.3893L12.0914 35.6677C12.0437 34.8547 11.819 33.7165 11.0298 32.894C10.389 32.2268 9.43724 31.8729 8.1987 31.8393V30.1585C9.43497 30.1274 10.3865 29.7735 11.0298 29.1039C11.819 28.2813 12.0437 27.1432 12.0938 26.3205V19.6086C12.0938 17.6501 13.7821 16.0554 15.8601 16.0554H17.4359V17.7338H15.8601C14.772 17.7338 13.8873 18.5756 13.8873 19.6086V26.0027C13.8873 26.8539 13.7342 28.4799 12.7179 29.8141C12.6079 29.9577 12.4956 30.0891 12.3761 30.2135C12.1537 30.4477 11.9025 30.6606 11.6276 30.8518Z"
          fill="white"
        />
        <path
          d="M51.7898 30.1585V31.8393C50.5535 31.8729 49.5995 32.2268 48.9586 32.894C48.1695 33.7165 47.9448 34.8547 47.8945 35.6774V42.3893C47.8945 44.3477 46.2064 45.9426 44.1284 45.9426H42.5526V44.2664H44.1284C45.2164 44.2664 46.1012 43.4247 46.1012 42.3893V35.9953C46.1012 35.1465 46.2541 33.5181 47.2705 32.1839C47.3804 32.0403 47.4928 31.9113 47.6124 31.7843C47.8348 31.5525 48.0858 31.3373 48.3608 31.146L48.576 31.0002L48.3608 30.8518C48.0858 30.6606 47.8348 30.4477 47.6124 30.2135C47.4928 30.0891 47.3804 29.9577 47.2705 29.8141C46.2541 28.4799 46.1012 26.8539 46.1012 26.0027V19.6086C46.1012 18.5756 45.2164 17.7338 44.1284 17.7338H42.5526V16.0554H44.1284C46.2064 16.0554 47.8945 17.6501 47.8945 19.6086V26.3326C47.9448 27.1432 48.1695 28.2813 48.9586 29.1039C49.5995 29.771 50.5511 30.1274 51.7898 30.1585Z"
          fill="white"
        />
      </g>
      <path
        d="M109.487 23.097L104.46 44.255H95.0815L92.5305 32.3255L89.8295 44.255H80.451L75.4616 23.097H83.7147L85.4779 35.9644L88.2164 23.097H97.1073L99.9959 36.1144L101.797 23.097H109.487Z"
        fill={color}
      />
      <path
        d="M121.316 44.5176C119.19 44.5176 117.277 44.0799 115.576 43.2046C113.876 42.3293 112.538 41.0788 111.562 39.4532C110.612 37.8026 110.137 35.8768 110.137 33.676C110.137 31.4752 110.612 29.5619 111.562 27.9363C112.538 26.2857 113.876 25.0227 115.576 24.1474C117.277 23.2721 119.19 22.8344 121.316 22.8344C123.442 22.8344 125.355 23.2721 127.056 24.1474C128.756 25.0227 130.082 26.2857 131.032 27.9363C132.007 29.5619 132.495 31.4752 132.495 33.676C132.495 35.8768 132.007 37.8026 131.032 39.4532C130.082 41.0788 128.756 42.3293 127.056 43.2046C125.355 44.0799 123.442 44.5176 121.316 44.5176ZM121.316 37.3149C122.091 37.3149 122.741 37.0148 123.267 36.4145C123.792 35.7893 124.054 34.8765 124.054 33.676C124.054 32.4755 123.792 31.5752 123.267 30.975C122.741 30.3497 122.091 30.0371 121.316 30.0371C120.541 30.0371 119.89 30.3497 119.365 30.975C118.84 31.5752 118.577 32.4755 118.577 33.676C118.577 34.8765 118.827 35.7893 119.328 36.4145C119.853 37.0148 120.516 37.3149 121.316 37.3149Z"
        fill={color}
      />
      <path
        d="M143.44 26.9985C144.29 25.773 145.328 24.7976 146.553 24.0724C147.779 23.3221 149.054 22.9469 150.38 22.9469V31.8378H148.016C146.441 31.8378 145.278 32.1129 144.528 32.6631C143.802 33.2133 143.44 34.1887 143.44 35.5892V44.255H135.111V23.097H143.44V26.9985Z"
        fill={color}
      />
      <path d="M160.951 16.4945V44.255H152.623V16.4945H160.951Z" fill={color} />
      <path
        d="M163.587 33.676C163.587 31.4502 163.962 29.5244 164.713 27.8988C165.488 26.2482 166.538 24.9977 167.864 24.1474C169.189 23.2721 170.677 22.8344 172.328 22.8344C173.703 22.8344 174.879 23.122 175.854 23.6972C176.855 24.2474 177.617 25.0102 178.143 25.9856V16.4945H186.471V44.255H178.143V41.3664C177.617 42.3418 176.855 43.1171 175.854 43.6923C174.879 44.2425 173.703 44.5176 172.328 44.5176C170.677 44.5176 169.189 44.0924 167.864 43.2421C166.538 42.3668 165.488 41.1163 164.713 39.4907C163.962 37.8401 163.587 35.9018 163.587 33.676ZM178.18 33.676C178.18 32.5506 177.892 31.6752 177.317 31.05C176.742 30.4248 176.004 30.1122 175.104 30.1122C174.204 30.1122 173.466 30.4248 172.891 31.05C172.315 31.6752 172.028 32.5506 172.028 33.676C172.028 34.8014 172.315 35.6768 172.891 36.302C173.466 36.9272 174.204 37.2398 175.104 37.2398C176.004 37.2398 176.742 36.9272 177.317 36.302C177.892 35.6768 178.18 34.8014 178.18 33.676Z"
        fill={color}
      />
      <defs>
        <clipPath id="clip0_1422_237">
          <rect
            width="60.0227"
            height="60.0227"
            fill="white"
            transform="translate(0.0111694 0.988647)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

CodeClubWorldLogo.propTypes = {
  scale: PropTypes.number,
  white: PropTypes.bool,
};

export default CodeClubWorldLogo;
